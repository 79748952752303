/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region Fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSans {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}
@font-face {
  font-family: 'Chunkfive';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/chunkfive.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/chunkfive.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/chunkfive.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/chunkfive.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/chunkfive.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/chunkfive.svg#chunkfive') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.ChunkFive {
  font-family: 'Chunkfive', sans-serif;
  font-weight: normal;
}
@font-face {
  font-family: 'Toonish';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/toonish.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/toonish.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/toonish.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/toonish.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/toonish.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/toonish.svg#toonish') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Toonish {
  font-family: 'Toonish', sans-serif;
}
@font-face {
  font-family: 'Dimbo';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.svg#dimbo') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Dimbo {
  font-family: 'Dimbo', sans-serif;
}
/*#endregion Fonts*/
.textContent {
  padding: 0;
}
.textContent h1 {
  color: #333333;
  font-family: 'Toonish', sans-serif;
  font-size: 4vw;
  text-align: center;
  position: relative;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-family: 'Chunkfive', sans-serif;
  font-weight: normal;
  text-shadow: none;
  margin-top: 0;
}
@media (min-width: 1330px) {
  .textContent h1 {
    font-size: 40px;
  }
}
.lower-content h2 {
  color: #333333;
  font-family: 'Toonish', sans-serif;
  font-size: 4vw;
  text-align: center;
  position: relative;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-family: 'Chunkfive', sans-serif;
  font-weight: normal;
  text-shadow: none;
}
@media (min-width: 1330px) {
  .lower-content h2 {
    font-size: 40px;
  }
}
.section-title {
  font-family: 'Toonish', sans-serif;
  font-size: 4vw;
  text-align: center;
  position: relative;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
@media (min-width: 1330px) {
  .section-title {
    font-size: 40px;
  }
}
html {
  font-size: 16px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  background: #fff;
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, from(#00b4ff), color-stop(25%, #5bbc6f), color-stop(50%, #ffc400), color-stop(75%, #ee2118), to(#2f2d2d));
  background: linear-gradient(to bottom, #00b4ff 0%, #5bbc6f 25%, #ffc400 50%, #ee2118 75%, #2f2d2d 100%);
}
body:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASBAMAAACQmVJ4AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAAFVBMVEUAAAD///////////////////////9Iz20EAAAAB3RSTlMAMwMQKwIs9MUVdAAAAE1JREFUCNdjEDNSThRkcGJgUBFkMGBgYBZkUGBgYIWREBGIrIiRsqMggyAIMJgJCiYrMTA5igCVMRgKAwmGQCEECRaByJoICjorQXQBALLnB/nWZDpIAAAAAElFTkSuQmCC");
  opacity: 0.3;
}
.outer-wrapper {
  overflow: hidden;
  max-width: 100vw;
  position: relative;
}
.outer-wrapper.outer-wrapper--alt .columns__container {
  padding-top: 30px;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    margin: 0 auto 0;
    padding: 5px 15px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto 0;
  }
}
@media (min-width: 1330px) {
  .container {
    width: 1170px;
    margin: 0 auto 0;
  }
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
  padding-top: 53px;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #98c21d;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  min-height: 5px;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: block !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
  position: relative;
}
.top-wrapper--alt .columns__left-social {
  visibility: visible !important;
}
.top-wrapper--alt .columns__container {
  margin-bottom: 0;
}
.top-wrapper--alt .banner__container {
  display: none;
}
.header {
  text-align: center;
  position: relative;
  z-index: 5;
}
@media (max-width: 767px) {
  .header {
    padding: 30px 15px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 14.26%;
  }
}
@media (max-width: 767px) {
  .header__BCN {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__BCN {
    position: absolute;
    width: 12.35%;
    bottom: 19.94%;
    left: 23.31%;
  }
}
@media (max-width: 767px) {
  .header__BCN img {
    max-width: 180px !important;
  }
}
.header__container {
  background: #fff;
  padding: 0;
}
@media (min-width: 768px) {
  .header__container {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 5%;
  }
}
.header__logo {
  display: none;
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 25.31%;
    bottom: 21%;
    left: 36.26%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    display: block;
    max-width: 400px;
    margin: 0 auto 20px;
  }
}
@media (max-width: 767px) {
  .header__social-links {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    width: 10.32%;
    bottom: 7.41%;
    left: 0%;
  }
}
@media (max-width: 767px) {
  .header__social-links {
    text-align: center;
    margin: 0 auto 30px;
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    width: 15.34%;
    bottom: 55.04%;
    left: 21.52%;
  }
}
.header__social-link {
  display: inline-block;
}
@media (max-width: 767px) {
  .header__social-link {
    width: 45px;
    margin: 0 5px;
  }
}
@media (min-width: 768px) {
  .header__social-link {
    max-width: 20%;
    margin: 0 2%;
  }
}
@media (max-width: 767px) {
  .header__call {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    width: 24.78%;
    bottom: 58.12%;
    right: 2.56%;
  }
}
@media (max-width: 767px) {
  .header__call {
    display: block;
    margin: 20px auto 0;
  }
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 33.06%;
    bottom: 22.51%;
    right: 2.56%;
  }
}
@media (max-width: 767px) {
  .header__phone {
    display: block;
    margin: 20px auto 0;
    max-width: 400px;
  }
}
.header__weather {
  position: absolute;
  width: 21.77%;
  height: 74.93%;
  left: -2%;
  bottom: 19.09%;
  z-index: 3;
}
.header__weather:hover .header__weather-sun-corona {
  -webkit-transform: rotateZ(90deg) scale(1.15);
          transform: rotateZ(90deg) scale(1.15);
}
.header__weather:hover .header__weather-sun-body {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.header__weather-bg {
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 80%;
  -webkit-filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.1));
}
.header__weather-sun {
  display: none;
}
.header__weather-sun-corona {
  position: absolute;
  top: 1.5%;
  left: -3.3%;
  width: 48%;
  -webkit-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
}
.header__weather-sun-body {
  position: absolute;
  width: 36%;
  top: 19%;
  left: 2.5%;
  -webkit-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
.header__weather-text {
  position: absolute;
  bottom: 16%;
  right: 5%;
  width: 55%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
.toplinks {
  border-bottom-right-radius: 15px;
  position: relative;
}
.toplinks__inner {
  position: relative;
  padding: 0 10px;
  background-size: auto 100%;
  background-color: #ffd500;
  background-color: #ffe100;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe100), to(#ffc700));
  background: linear-gradient(to bottom, #ffe100, #ffc700);
  z-index: 4;
  width: calc(100% + 100px);
  left: -50px;
  font-family: 'Dimbo', sans-serif;
  padding-right: 50px;
  padding-left: 50px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.toplinks:before,
.toplinks:after {
  content: "";
  position: absolute;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNTIgMTgwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNTIgMTgwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7ZmlsbDojRTVCQTAwO30NCgkuc3Qxe29wYWNpdHk6MC40O2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCwxODBsNTYtODBMMCwyMEwxNTIsMHYxNjBMMCwxODB6Ii8+DQo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNOTIsMTAwbDYwLDYwdi02MEg5MnoiLz4NCjwvc3ZnPg0K') /*sash.svg*/;
  background-size: 100% 100%;
  width: 6%;
  top: 47%;
  height: 100%;
  z-index: 0;
}
.toplinks:before {
  left: calc(-6% - 19px);
}
.toplinks:after {
  right: calc(-6% - 19px);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  text-align: center;
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 10px 5px;
}
.toplinks__li.open .dropdown-toggle:focus {
  color: #ffea3f;
}
.toplinks__link {
  color: #000;
  background: none;
  font-size: 18px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1rem;
  white-space: nowrap;
  display: block;
  border-radius: 10px;
  padding: 10px 5px;
  position: relative;
  text-shadow: 1px 0 #ffff00;
}
@media (min-width: 1330px) {
  .toplinks__link {
    font-size: 1.6rem;
  }
}
.toplinks__link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #4a4a4a;
  background: -webkit-gradient(linear, left top, left bottom, from(#4a4a4a), to(#262626));
  background: linear-gradient(to bottom, #4a4a4a, #262626);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 10px;
  z-index: -1;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #fff;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.toplinks__link:hover:before,
.toplinks__link.active:before,
.toplinks__link:focus:before {
  opacity: 1;
}
.toplinks__dropdown {
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 0;
  background-color: #4a4a4a;
  background: -webkit-gradient(linear, left top, left bottom, from(#4a4a4a), to(#262626));
  background: linear-gradient(to bottom, #4a4a4a, #262626);
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
}
.toplinks__dropdown-li {
  display: block;
  width: 100%;
  margin-left: 0;
  border-radius: 0;
}
.toplinks__dropdown-link {
  padding: 5px 10px !important;
  font-size: 1.8vw;
  color: #fff !important;
}
@media (min-width: 1330px) {
  .toplinks__dropdown-link {
    font-size: 20px;
  }
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: #d93133;
  background: -webkit-gradient(linear, left top, left bottom, from(#d93133), to(#f03639));
  background: linear-gradient(to bottom, #d93133, #f03639);
  color: #ffea3f !important;
}
.search-bar {
  margin: 10px auto 0;
  padding: 0 10px;
  width: 100%;
  position: relative;
}
.search-bar input {
  border: none;
  border-radius: 25px;
  width: 100%;
  -webkit-filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.2));
  min-height: 40px;
}
@media (min-width: 768px) {
  .search-bar input {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .search-bar input {
    background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/026/binoculars.png");
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: 10px 5px;
    padding-left: 32%;
  }
}
.home-gallery {
  background: #fff;
}
@media (max-width: 767px) {
  .home-gallery {
    display: none;
  }
}
@media (min-width: 768px) {
  .home-gallery {
    display: block;
    position: relative;
    height: 160px;
    padding: 20px 0;
  }
}
.home-gallery__previous,
.home-gallery__next {
  display: block;
  width: 20px;
  height: 44px;
  position: absolute;
  background-size: 100% 100%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/026/gallery-arrow.svg");
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: transform 0.5s ease-in-out;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  top: 37px;
  width: 40px;
  height: 88px;
}
.home-gallery__previous:hover,
.home-gallery__next:hover {
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  z-index: 100;
}
.home-gallery__next {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  right: 5px;
}
.home-gallery__next:hover {
  -webkit-transform: scaleX(-1) scale(1.1);
  transform: scaleX(-1) scale(1.1);
}
.home-gallery__previous {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  left: 5px;
}
.home-gallery__previous:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.home-gallery__outer {
  top: 20px;
  bottom: 0;
  left: 60px;
  right: 60px;
  overflow: hidden;
  position: absolute;
}
.home-gallery__inner {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: -141px;
  right: -141px;
}
.home-gallery__slide {
  float: left;
  width: 140px;
  height: 140px;
}
.home-gallery__link {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  padding: 0;
  margin: 0 auto;
  border: 3px solid #ababab;
  background-color: #fff;
}
.home-gallery__link:hover {
  border-color: #C00;
}
.banner {
  background-color: #fff;
  position: relative;
}
.banner #CarouselContainer {
  padding-top: 39.56%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner__container {
  padding-top: 30px;
  margin-top: -10px;
  background: #fff;
}
@media (min-width: 768px) {
  .banner__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.banner__left {
  width: 100%;
}
@media (min-width: 768px) {
  .banner__left-has-booking {
    width: 73.52%;
  }
}
.banner__right {
  width: 26%;
  background: #f7644b;
  background: radial-gradient(ellipse at center, #f7644b 0%, #f11f0b 100%);
  border-radius: 5px;
  position: relative;
}
.banner__right:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-size: 7px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOAgMAAABiJsVCAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAADFBMVEUAAAAAAAAAAAAAAAA16TeWAAAABHRSTlMADQcaCWiadAAAAChJREFUCNdjuMDAIMAQxsCgwCDLAASqCUCCESjIwBQG4hMWTGBgCgAA8yMHEmDIg7sAAAAASUVORK5CYII=");
}
.banner__right:after {
  content: "";
  position: absolute;
  width: 91%;
  height: 58%;
  top: 36%;
  left: 4.5%;
  border-radius: 5px;
  border: 2px dashed #fff;
  -webkit-filter: drop-shadow(1px 1px 1px #000);
          filter: drop-shadow(1px 1px 1px #000);
}
.banner__right #PostcodeSearchLeft input {
  position: absolute;
  border-radius: 25px;
  top: 18.33%;
  height: 11.11%;
  left: 18.44%;
  width: 75.63%;
  padding-left: 10%;
}
.banner__right .SearchDate,
.banner__right .SearchCategory {
  width: 71.88%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 11.11%;
  border-radius: 25px;
  border: none;
  z-index: 1;
}
.banner__right .SearchDate {
  top: 55.56%;
  padding-left: 10px;
}
.banner__right .SearchCategory {
  top: 72.22%;
}
.banner__map-icon {
  position: absolute;
  width: 33.5%;
  left: 0;
  top: 14.25%;
  z-index: 1;
}
.banner__check-area {
  width: 71.78%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 6.17%;
}
.banner__check-availability {
  width: 67.66%;
  top: 44.58%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.featured-categories {
  margin: 20px auto 10px;
}
@media (max-width: 420px) {
  .featured-categories__col {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .featured-categories__col {
    width: 50%;
  }
}
.featured-categories__image-panel {
  padding-top: 79.333%;
  position: relative;
}
.featured-categories img {
  width: 100%;
  max-width: none !important;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.featured-categories__category-panel {
  position: relative;
  margin-bottom: 10px;
  display: block;
}
.columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 15px;
  position: relative;
  z-index: 1;
}
.columns__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.columns__left {
  position: relative;
  background: #4c4c4c;
  z-index: 2;
  border-radius: 10px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.columns__container {
  background: #fff;
  z-index: 1;
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.category-links {
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
}
.category-links__link,
.category-links__child-link {
  position: relative;
  display: block;
  margin-top: 5%;
  text-align: left;
  font-family: 'Dimbo', sans-serif;
  text-shadow: 1px 1px 2px #000;
  color: white;
  background-size: 100% 100%;
  border-radius: 5px;
  padding: 8px 15px;
  overflow: hidden;
  background-color: #ffcb00;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffcb00), to(#ffb900));
  background: linear-gradient(to bottom, #ffcb00, #ffb900);
  border-top: 1px solid #f3f005;
  border-bottom: 1px solid #ffc400;
  -webkit-transition: padding 0.3s;
  transition: padding 0.3s;
}
@media (min-width: 1330px) {
  .category-links__link,
  .category-links__child-link {
    font-size: 20px;
  }
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-left: 8px solid #4c4c4c;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  color: #fff;
  padding-left: 20px;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before,
.category-links__link.active:before,
.category-links__child-link.active:before {
  opacity: 1;
}
.category-links__child-link {
  margin-left: 10px;
}
/*#region Other Left Column Stuff*/
.left-logo {
  margin-top: 20px;
  position: relative;
  z-index: 1;
}
.left-block {
  background: #40e27a;
  background: radial-gradient(ellipse at center, #40e27a 0%, #00c523 100%);
  position: relative;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  border: 3px solid #fff;
}
.left-block:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-size: 24px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAA1NJREFUWIXFl9uSozYQhn9hzhgwPsB4Jt6X8MNvqrJbtblIKpVk8xAZn2CMDRh71zaIvUilJTm1t0F3n5BEi+7+u2GfPv64vHz5gn+HxjQwTSNmDNAYI+YdB+ecOPB9BL5PfL3dcL3diNu2Rdu2xLZtw3ZsYr2sSpyqE01MJhNEo4i4KArsDwfi8WSM6WwmDhgMAGEfRqMQtu0Q//36is1mSxyNIwx0XVwYPY/eDdAdx0EYhjRhWRZM0yAOwwC2bRG3LcfxWBAH/hCmPyQ+FiUulx1x0zSIIuHStmmRpplqwHw+VzY0zZ3Yti1o2oA4TTPspQNM00AYBsRFUeJ1tSaOZzPMZlPiLMuQZmJ/7y7o3QD9fm+w26U0YdmW4vO6PuPr1ytxByCORRp2vMNW2s85RyylqeM6im74gQ/TMmUD7silPJ9OJ7CkBfX5jDwXz2ezKeJY+HS3S5ULjMdjxeec/1e4bGl/7y7o3QD28cP7pWGIvOeco5M+maZp0AbCTsuyYFlyjNSo67M4seuUFzyub5o7mrYh1nXdQJIkNJGmKdLsjTh5ShDHMfHtdsP1KoLy8QWH/IA8z4mjKIJti+JTn884Ho/iguh59G4A++vzb8sgEFJaFiXKshQLGJRy63kePM8jLh7WG4YBU4qpf6Rd+FzTmBJTuu/7itZ7ngddF5xlGTJJuxeLBaZTWdvflOLy7t0C82dRW3bbHfb7PXEcq7Whdxf0bgD74/dflqPRiCaqqkJVVcQd5+g6oQtM06BJPWNzb9BKPh5FEaKxep7c8rVti1bSGb0oCgyHoqE4ny/IDyJPp5MxphNRXLbbHVbbFfFTkmD+JHRENwx0khh5rgtb0on1Zov1ekPcuwt6N4D9/OmnJZMSvX0on67rwHNFm32qTjidTuohkk4EYYhAatGqskIpxRQDU9brlmVi9Sp6uCiKEEUiiBiD8mPhuI7SsKRZhnQndKADg+u6xEVRYi39Fyx+eMHLyzNx7y7o3QD2+c9flwcp7TreKXnveR6GQ6H99alGXdffPbDrOnApDbuH52EYYCTFiG4YpvJjkue5ot0JY/AlnbhcLnh7k/qFJEYi9Qur9QYrKc/nz3PM50/EA00Db6WG57tX+Z9G7wZ8A0IRaqkFLAytAAAAAElFTkSuQmCC");
}
.left-block img {
  position: relative;
}
.left-block--lower {
  margin-top: auto;
}
.left-block__spacer {
  padding-bottom: 150%;
}
/*#endregion Other Left Column Stuff*/
.testimonials {
  background-color: #f2f3f2;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 20px 10px 10px;
}
.testimonials__bg {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #bdbdbd;
  padding: 10px;
  margin-bottom: 20px;
}
.testimonials__bg:last-child {
  margin-bottom: 0;
}
.testimonials__text {
  color: #888888;
}
.testimonials__author {
  color: #e50000;
}
.testimonials .carousel {
  border: 3px solid #ffbf00;
  border-radius: 10px;
  padding: 10px;
}
.testimonials__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.badges {
  background-color: #f2f3f2;
  background: -webkit-gradient(linear, left top, left bottom, from(#f2f3f2), to(rgba(242, 243, 242, 0)));
  background: linear-gradient(to bottom, #f2f3f2, rgba(242, 243, 242, 0));
  padding: 20px;
  text-align: center;
}
.badges #BIHA,
.badges #TIPE,
.badges #PIPA {
  display: inline-block;
  margin: 0 1%;
}
.badges #BIHA {
  width: 23%;
}
.badges #TIPE {
  width: 23%;
}
.badges #PIPA {
  width: 40%;
  margin-top: -5%;
}
/*#region BootstrapCarouselFade*/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: auto;
  width: 100%;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/*#endregion BootstrapCarouselFade*/
/*#region Castle Panels*/
.page-listings {
  border-radius: 10px;
  background: #e6e6e6;
  padding: 10px;
}
@media (min-width: 768px) {
  .page-listings {
    padding: 20px;
  }
}
.category-title {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: -webkit-gradient(linear, left top, left bottom, from(#fa1b1b), color-stop(50%, #fa1b1b), color-stop(51%, #eb1212), to(#eb1212));
  background: linear-gradient(to bottom, #fa1b1b 0%, #fa1b1b 50%, #eb1212 51%, #eb1212 100%);
  padding: 15px;
  font-family: 'Chunkfive', sans-serif;
  font-weight: normal;
}
#PageListings {
  margin-left: 0;
  margin-right: 0;
}
#PageListings > div {
  padding-left: 8px;
  padding-right: 8px;
}
@media (max-width: 420px) {
  #PageListings > div {
    width: 100%;
  }
}
.castlePanel {
  width: 100%;
  border: none;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  margin: 0 0 30px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .castlePanel {
    text-align: center;
  }
}
.castlePanel:hover {
  -webkit-box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
          box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
}
.castlePanel:hover .castleCheckBook {
  color: yellow;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/026/go-button-hover.svg");
}
.castlePanel .SingleImagePanel {
  border: 3px solid #ffc400;
  border-radius: 5px;
}
.castlePanel .SingleImagePanel img {
  padding: 5px;
}
.castlePanel .castleTitlePanel {
  margin-top: 15px;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  color: #404040;
  font-size: 20px;
  position: relative;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  color: #404040;
  font-family: 'Dimbo', sans-serif;
  font-size: 35px;
  position: relative;
  text-align: left;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  color: #ff0000;
  font-size: 20px;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 24px;
}
@media (max-width: 767px) {
  .castlePanel .castlePriceDefault,
  .castlePanel .castlePriceDiscounted {
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.castlePanel .castlePriceDefault {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleCheckBook {
  background-color: transparent;
  position: relative;
  font-size: 0;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/026/go-button.svg"), url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/026/go-button-hover.svg");
  width: 23%;
  height: 0;
  padding-top: 23%;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 5%;
  bottom: 10px;
}
@media (max-width: 767px) {
  .castlePanel .castleCheckBook {
    position: relative;
    display: inline-block;
    bottom: auto;
    right: auto;
  }
}
/*#endregion Castle Panels*/
.DetailsTitle h1,
.DetailsMainImage {
  background-color: #E32121;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton {
  width: auto;
  display: inline-block;
  margin: 20px 0 10px;
  float: none;
}
.footer__wrapper {
  background-color: #000;
  color: #fff;
  padding: 25px 10px;
  width: 100%;
  z-index: 1;
  position: relative;
}
@media (max-width: 767px) {
  .footer__wrapper {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.footer__links {
  text-align: center;
}
@media (max-width: 767px) {
  .footer__links {
    text-align: center;
    margin-top: 15px;
  }
}
.footer__links a {
  color: white;
  margin: 0 5px 5px;
  display: inline-block;
}
@media (max-width: 767px) {
  .footer__links a {
    padding: 8px 0;
  }
}
.footer__BCN {
  display: block;
  max-width: 180px;
}
@media (max-width: 767px) {
  .footer__BCN {
    text-align: center;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .footer__BCN {
    float: right;
  }
}
.footer__BCN img {
  margin: 0 auto;
}
#BouncyCastleHireLink {
  color: white;
  font-weight: 700;
}
/*#region Utiliy Classes*/
.white {
  color: #fff;
}
.text-shadow-big {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}
/*#endregion Utiliy Classes*/
/*#region Animations*/
.wow {
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}
/*#endregion Animations*/
