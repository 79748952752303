@import "../../Autobopr/Assets/Functions.less";
@import (inline) "../../Autobopr/Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1329px)";
@desktop: ~"(min-width: 1330px)";
@mobileNavColor: #98c21d;
@castleDetailsColor: #E32121;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/026/";

/*#region Fonts*/
.OpenSans, .BodyFont { // .BodyFont used in #ToplinksMobile to override .nicefont
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSans {
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
}

.font-Chunkfive;

.ChunkFive {
	font-family: 'Chunkfive',sans-serif;
	font-weight: normal;
}

.font-Toonish;

.Toonish {
	font-family: 'Toonish', sans-serif;
}

.font-Dimbo;

.Dimbo {
	font-family: 'Dimbo', sans-serif;
}
/*#endregion Fonts*/
#BodyContent {
}

.textContent {
	padding: 0;

	h1 {
		color: #333333;
		.section-title;
		.ChunkFive;
		text-shadow: none;
		margin-top: 0;
	}
}

.lower-content h2 {
	color: #333333;
	.section-title;
	.ChunkFive;
	text-shadow: none;
}

.section-title {
	.Toonish;
	font-size: 4vw;
	text-align: center;
	position: relative;
	text-shadow: 1px 1px 2px rgba(0,0,0,0.3);

	@media @desktop {
		font-size: 40px;
	}
}

.nicefont, h1, h2, h3 {
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
	background: #fff;
	position: relative;
	background: linear-gradient(to bottom, #00b4ff 0%,#5bbc6f 25%,#ffc400 50%,#ee2118 75%,#2f2d2d 100%);

	&:before {
		.StickToEdges;
		background-repeat: repeat;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASBAMAAACQmVJ4AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAAFVBMVEUAAAD///////////////////////9Iz20EAAAAB3RSTlMAMwMQKwIs9MUVdAAAAE1JREFUCNdjEDNSThRkcGJgUBFkMGBgYBZkUGBgYIWREBGIrIiRsqMggyAIMJgJCiYrMTA5igCVMRgKAwmGQCEECRaByJoICjorQXQBALLnB/nWZDpIAAAAAElFTkSuQmCC");
		opacity: 0.3;
	}
}

.outer-wrapper {
	overflow: hidden;
	max-width: 100vw;
	position: relative;

	&.outer-wrapper--alt {
		.columns__container {
			padding-top: 30px;
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		margin: 0 auto 0;
		padding: 5px 15px;
	}

	@media @tablet {
		width: 100%;
		padding: 0 20px;
		margin: 0 auto 0;
	}

	@media @desktop {
		width: 1170px;
		margin: 0 auto 0;
	}
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
	padding-top: 53px;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();
	min-height: 5px;

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	@media @sm {
		display: block !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
	position: relative;

	&--alt {
		.columns__left-social {
			visibility: visible !important;
		}

		.columns__container {
			margin-bottom: 0;
		}

		.banner__container {
			display: none;
		}
	}
}

.header {
	@width: 2462px;
	@height: 351px;
	text-align: center;
	position: relative;
	z-index: 5;

	@media @mobile {
		padding: 30px 15px;
	}

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	&__BCN {
		.TopBarItem(304,@width,@height,auto,70,left,574);

		img {
			@media @mobile {
				max-width: 180px !important;
			}
		}
	}

	&.header--alt {
	}

	&__container {
		background: #fff;
		padding: 0;

		@media @tablet {
			.border-top-radius(10px);
			margin-top: 5%;
		}
	}

	&__logo {
		display: none;

		@media @tablet {
			position: absolute;
			width: 25.31%;
			bottom: 21%;
			left: 36.26%;
		}

		@media @mobile {
			display: block;
			max-width: 400px;
			margin: 0 auto 20px;
		}
	}

	&__social-links {
		.TopBarItem(254,@width,@height,auto,26,left,0);

		@media @mobile {
			text-align: center;
			margin: 0 auto 30px;
			padding: 0 30px;
		}

		@media @tablet {
			width: 15.34%;
			bottom: 55.04%;
			left: 21.52%;
		}
	}

	&__social-link {
		display: inline-block;

		@media @mobile {
			width: 45px;
			margin: 0 5px;
		}

		@media @tablet {
			max-width: 20%;
			margin: 0 2%;
		}
	}

	&__call {
		.TopBarItem(610,@width,@height,auto,204,right,63);

		@media @mobile {
			display: block;
			margin: 20px auto 0;
		}
	}

	&__phone {
		.TopBarItem(814,@width,@height,auto,79,right,63);

		@media @mobile {
			display: block;
			margin: 20px auto 0;
			max-width: 400px;
		}
	}

	&__weather {
		position: absolute;
		width: round(percentage(536/@width),2);
		height: round(percentage(263/@height),2);
		left: -2%;
		bottom: round(percentage(67/@height),2);
		z-index: 3;

		&:hover {
			.header__weather-sun-corona {
				transform: rotateZ(90deg) scale(1.15);
			}

			.header__weather-sun-body {
				transform: scale(1.2);
			}
		}
	}

	&__weather-bg {
		//edit fill color in svg
		position: absolute;
		bottom: 0;
		left: 20%;
		width: 80%;
		filter: drop-shadow(0 10px 5px rgba(0,0,0,0.1));
	}

	&__weather-sun {
		display: none;
	}

	&__weather-sun-corona {
		position: absolute;
		top: 1.5%;
		left: -3.3%;
		width: 48%;
		transition: all 3s ease-in-out;
	}

	&__weather-sun-body {
		position: absolute;
		width: 36%;
		top: 19%;
		left: 2.5%;
		transition: all 3s ease-in-out;
		transform-origin: center center;
	}

	&__weather-text {
		position: absolute;
		bottom: 16%;
		right: 5%;
		width: 55%;
		transform-origin: center center;
	}
}

.toplinks {
	border-bottom-right-radius: 15px;
	position: relative;

	&__inner {
		position: relative;
		padding: 0 10px;
		background-size: auto 100%;
		background-color: #ffd500;
		.Gradient(#ffe100,#ffc700);
		z-index: 4;
		width: ~'calc(100% + 100px)';
		left: -50px;
		.Dimbo;
		padding-right: 50px;
		padding-left: 50px;
		box-shadow: 0 5px 10px rgba(0,0,0,0.3);
	}

	&__nav {
	}

	&:before, &:after {
		content: "";
		position: absolute;
		//edit fill color in sash.svg
		background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNTIgMTgwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNTIgMTgwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7ZmlsbDojRTVCQTAwO30NCgkuc3Qxe29wYWNpdHk6MC40O2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCwxODBsNTYtODBMMCwyMEwxNTIsMHYxNjBMMCwxODB6Ii8+DQo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNOTIsMTAwbDYwLDYwdi02MEg5MnoiLz4NCjwvc3ZnPg0K') /*sash.svg*/;
		background-size: 100% 100%;
		width: 6%;
		top: 47%;
		height: 100%;
		z-index: 0;
	}

	&:before {
		left: ~'calc(-6% - 19px)';
	}

	&:after {
		right: ~'calc(-6% - 19px)';
		transform: rotateY(180deg);
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		text-align: center;
		list-style: none;
		position: relative;
		.Transition3s();
		flex-grow: 1;
		padding: 10px 5px;

		&.open .dropdown-toggle:focus {
			color: #ffea3f;
		}
	}

	&__link {
		color: #000;
		background: none;
		font-size: 18px;
		.transition(all 0.3s);
		font-size: 1rem;
		white-space: nowrap;
		display: block;
		border-radius: 10px;
		padding: 10px 5px;
		position: relative;
		text-shadow: 1px 0 #ffff00;

		@media @desktop {
			font-size: 1.6rem;
		}

		&:before {
			.StickToEdges;
			.Gradient(#4a4a4a,#262626);
			opacity: 0;
			transition: all 0.3s;
			border-radius: 10px;
			z-index: -1;
		}

		&:hover, &.active, &:focus {
			color: #fff;
			text-shadow: 2px 2px rgba(0,0,0,0.2);

			&:before {
				opacity: 1;
			}
		}
	}

	&__dropdown {
		background-color: rgba(0,0,0,0.8);
		text-align: center;
		padding: 0;
		.Gradient(#4a4a4a,#262626);
		column-count: 2;
	}

	&__dropdown-li {
		display: block;
		width: 100%;
		margin-left: 0;
		border-radius: 0;
	}

	&__dropdown-link {
		padding: 5px 10px !important;
		font-size: 1.8vw;
		color: #fff !important;

		@media @desktop {
			font-size: 20px;
		}

		&:hover, &:focus, &.active {
			.Gradient(#d93133,#f03639);
			color: #ffea3f !important;
		}
	}
}

.search-bar {
	margin: 10px auto 0;
	padding: 0 10px;
	width: 100%;
	position: relative;

	input {
		border: none;
		border-radius: 25px;
		width: 100%;
		filter: drop-shadow(0 4px 2px rgba(0,0,0,0.2));
		min-height: 40px;

		@media @sm {
			text-align: center;
		}

		@media @md {
			background-image: url("@{cloudUrl}binoculars.png");
			background-repeat: no-repeat;
			background-size: 25%;
			background-position: 10px 5px;
			padding-left: 32%;
		}
	}
}

.home-gallery {
	background: #fff;

	@media @mobile {
		display: none;
	}

	@media @tablet {
		display: block;
		position: relative;
		height: 160px;
		padding: 20px 0;
	}

	&__previous, &__next {
		display: block;
		.size(20px, 44px);
		position: absolute;
		background-size: 100% 100%;
		//edit fill colors in gallery-arrow.svg
		background-image: url("@{cloudUrl}gallery-arrow.svg");
		.backface-visibility(hidden);
		.transition(transform 0.5s ease-in-out);
		top: 37px;
		.size(40px, 88px);

		&:hover {
			.transition-timing-function(cubic-bezier(0.47, 2.02, 0.31, -0.36));
			z-index: 100;
		}
	}

	&__next {
		.Transform(scaleX(-1));
		right: 5px;

		&:hover {
			.Transform(scaleX(-1) scale(1.1));
		}
	}

	&__previous {
		.Transform(translateZ(0));
		left: 5px;

		&:hover {
			.Transform(scale(1.1));
		}
	}

	&__outer {
		top: 20px;
		bottom: 0;
		left: 60px;
		right: 60px;
		overflow: hidden;
		position: absolute;
	}

	&__inner {
		//@Size: 120px;
		position: absolute;
		overflow: hidden;
		top: 0;
		bottom: 0;
		left: -141px;
		right: -141px;
	}

	&__slide {
		float: left;
		width: 140px;
		height: 140px;
	}

	&__link {
		display: block;
		border-radius: 5px;
		overflow: hidden;
		width: 120px;
		height: 120px;
		padding: 0;
		margin: 0 auto;
		border: 3px solid #ababab;
		background-color: #fff;

		&:hover {
			border-color: #C00;
		}
	}
}

.banner {
	background-color: #fff;
	position: relative;
	.BannerPanelSet(39.56%);
	@width: 2462px;
	@rightWidth: 640px;
	@height: 720px;

	&__container {
		padding-top: 30px;
		margin-top: -10px;
		background: #fff;
	}

	&__inner {
		@media @tablet {
			display: flex;
			justify-content: space-between;
		}
	}

	&__left{
		width:100%;
	}

	&__left-has-booking {
		@media @tablet {
			width: round(percentage(1810/@width),2);
		}
	}

	&__right {
		width: round(percentage(640/@width),2);
		.RadialGradient(#f7644b,#f11f0b);
		border-radius: 5px;
		position: relative;

		&:before {
			.StickToEdges;
			background-repeat: repeat;
			background-size: 7px;
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOAgMAAABiJsVCAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAADFBMVEUAAAAAAAAAAAAAAAA16TeWAAAABHRSTlMADQcaCWiadAAAAChJREFUCNdjuMDAIMAQxsCgwCDLAASqCUCCESjIwBQG4hMWTGBgCgAA8yMHEmDIg7sAAAAASUVORK5CYII=");
		}

		&:after {
			content: "";
			position: absolute;
			width: 91%;
			height: 58%;
			top: 36%;
			left: 4.5%;
			border-radius: 5px;
			border: 2px dashed #fff;
			filter: drop-shadow(1px 1px 1px #000);
		}

		#PostcodeSearchLeft input {
			position: absolute;
			border-radius: 25px;
			top: round(percentage(132/@height),2);
			height: round(percentage(80/@height),2);
			left: 18.44%;
			width: 75.63%;
			padding-left: 10%;
		}

		.SearchDate, .SearchCategory {
			width: round(percentage(460/@rightWidth),2);
			.TransformMiddleH;
			height: round(percentage(80/@height),2);
			border-radius: 25px;
			border: none;
			z-index: 1;
		}

		.SearchDate {
			top: round(percentage(400/@height),2);
			padding-left: 10px;
		}

		.SearchCategory {
			top: round(percentage(520/@height),2);
		}
	}

	&__map-icon {
		position: absolute;
		width: 33.5%;
		left: 0;
		top: 14.25%;
		z-index: 1;
	}

	&__check-area {
		position: absolute;
		width: 71.78%;
		.TransformMiddleH;
		top: 6.17%;
	}

	&__check-availability {
		position: absolute;
		width: round(percentage(433/@rightWidth),2);
		top: round(percentage(321/@height),2);
		.TransformMiddleH;
	}
}

.featured-categories {
	margin: 20px auto 10px;

	@media @mobile {
	}

	&__col {
		@media (max-width:420px) {
			width: 100%;
		}

		@media (max-width:600px) {
			width: 50%;
		}
	}

	&__image-panel {
		padding-top: 79.333%;
		position: relative;
	}

	img {
		width: 100%;
		max-width: none !important;
		transition: opacity 0.3s;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		object-fit: cover;
	}

	&__category-panel {
		position: relative;
		margin-bottom: 10px;
		display: block;
	}
}

.columns {
	display: flex;
	border-radius: 15px;
	position: relative;
	z-index: 1;

	&__row {
		display: flex;
	}

	&__left {
		position: relative;
		background: #4c4c4c;
		z-index: 2;
		border-radius: 10px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__container {
		background: #fff;
		z-index: 1;
		padding-bottom: 15px;
		margin-bottom: 30px;
		.border-bottom-radius(10px);
	}
}

.category-links {
	margin-bottom: 10px;
	position: relative;
	padding: 10px;

	&__link, &__child-link {
		position: relative;
		display: block;
		margin-top: 5%;
		text-align: left;
		.Dimbo;
		.TextShadow1();
		color: white;
		background-size: 100% 100%;
		border-radius: 5px;
		padding: 8px 15px;
		overflow: hidden;
		.Gradient(#ffcb00,#ffb900);
		border-top: 1px solid #f3f005;
		border-bottom: 1px solid #ffc400;
		transition:padding 0.3s;
		//#4c4c4c

		@media @desktop {
			font-size: 20px;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			border-left: 8px solid #4c4c4c;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			opacity: 0;
			transition: opacity 0.3s;
		}

		&:hover,&:focus, &.active {
			color: #fff;
			padding-left:20px;

			&:before{
				opacity:1;
			}
		}
	}

	&__child-link {
		margin-left: 10px;
	}
}
/*#region Other Left Column Stuff*/
.left-logo {
	margin-top: 20px;
	position: relative;
	z-index: 1;
}

.left-block {
	.RadialGradient(#40e27a,#00c523);
	position: relative;
	text-align: center;
	border-radius: 10px;
	padding: 5px;
	border: 3px solid #fff;

	&:before {
		.StickToEdges;
		background-repeat: repeat;
		background-size: 24px;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAA1NJREFUWIXFl9uSozYQhn9hzhgwPsB4Jt6X8MNvqrJbtblIKpVk8xAZn2CMDRh71zaIvUilJTm1t0F3n5BEi+7+u2GfPv64vHz5gn+HxjQwTSNmDNAYI+YdB+ecOPB9BL5PfL3dcL3diNu2Rdu2xLZtw3ZsYr2sSpyqE01MJhNEo4i4KArsDwfi8WSM6WwmDhgMAGEfRqMQtu0Q//36is1mSxyNIwx0XVwYPY/eDdAdx0EYhjRhWRZM0yAOwwC2bRG3LcfxWBAH/hCmPyQ+FiUulx1x0zSIIuHStmmRpplqwHw+VzY0zZ3Yti1o2oA4TTPspQNM00AYBsRFUeJ1tSaOZzPMZlPiLMuQZmJ/7y7o3QD9fm+w26U0YdmW4vO6PuPr1ytxByCORRp2vMNW2s85RyylqeM6im74gQ/TMmUD7silPJ9OJ7CkBfX5jDwXz2ezKeJY+HS3S5ULjMdjxeec/1e4bGl/7y7o3QD28cP7pWGIvOeco5M+maZp0AbCTsuyYFlyjNSo67M4seuUFzyub5o7mrYh1nXdQJIkNJGmKdLsjTh5ShDHMfHtdsP1KoLy8QWH/IA8z4mjKIJti+JTn884Ho/iguh59G4A++vzb8sgEFJaFiXKshQLGJRy63kePM8jLh7WG4YBU4qpf6Rd+FzTmBJTuu/7itZ7ngddF5xlGTJJuxeLBaZTWdvflOLy7t0C82dRW3bbHfb7PXEcq7Whdxf0bgD74/dflqPRiCaqqkJVVcQd5+g6oQtM06BJPWNzb9BKPh5FEaKxep7c8rVti1bSGb0oCgyHoqE4ny/IDyJPp5MxphNRXLbbHVbbFfFTkmD+JHRENwx0khh5rgtb0on1Zov1ekPcuwt6N4D9/OmnJZMSvX0on67rwHNFm32qTjidTuohkk4EYYhAatGqskIpxRQDU9brlmVi9Sp6uCiKEEUiiBiD8mPhuI7SsKRZhnQndKADg+u6xEVRYi39Fyx+eMHLyzNx7y7o3QD2+c9flwcp7TreKXnveR6GQ6H99alGXdffPbDrOnApDbuH52EYYCTFiG4YpvJjkue5ot0JY/AlnbhcLnh7k/qFJEYi9Qur9QYrKc/nz3PM50/EA00Db6WG57tX+Z9G7wZ8A0IRaqkFLAytAAAAAElFTkSuQmCC");
	}

	img {
		position: relative;
	}

	&--lower {
		margin-top: auto;
	}

	&__spacer {
		padding-bottom: 150%;
	}
}
/*#endregion Other Left Column Stuff*/
.testimonials {
	background-color: #f2f3f2;
	.border-top-radius(10px);
	padding: 20px 10px 10px;

	&__bg {
		border-radius: 10px;
		background: #fff;
		border: 1px solid #bdbdbd;
		padding: 10px;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__text {
		color: #888888;
	}

	&__author {
		color: #e50000;
	}

	.carousel {
		border: 3px solid #ffbf00;
		border-radius: 10px;
		padding: 10px;
	}

	&__link {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.badges {
	.Gradient(#f2f3f2,fade(#f2f3f2,0%));
	padding: 20px;
	text-align: center;

	#BIHA, #TIPE, #PIPA {
		display: inline-block;
		margin: 0 1%;
	}

	#BIHA {
		width: 23%;
	}

	#TIPE {
		width: 23%;
	}

	#PIPA {
		width: 40%;
		margin-top: -5%;
	}
}
/*#region BootstrapCarouselFade*/
.carousel-fade .carousel-inner .item {
	opacity: 0;
	transition-property: opacity;
	transition-duration: 2s;
}

.carousel-fade .carousel-inner .active {
	opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	left: auto;
	width: 100%;
	opacity: 0;
	z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
	opacity: 1;
}

.carousel-fade .carousel-control {
	z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
	.carousel-fade .carousel-inner > .item.next,
	.carousel-fade .carousel-inner > .item.active.right {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.carousel-fade .carousel-inner > .item.prev,
	.carousel-fade .carousel-inner > .item.active.left {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.carousel-fade .carousel-inner > .item.next.left,
	.carousel-fade .carousel-inner > .item.prev.right,
	.carousel-fade .carousel-inner > .item.active {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
/*#endregion BootstrapCarouselFade*/
/*#region Castle Panels*/
.page-listings {
	border-radius: 10px;
	background: #e6e6e6;
	padding: 10px;

	@media @tablet {
		padding: 20px;
	}
}

.category-title {
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
	border-radius: 10px;
	background: linear-gradient(to bottom, #fa1b1b 0%,#fa1b1b 50%,#eb1212 51%,#eb1212 100%);
	padding: 15px;
	.ChunkFive;
}

#PageListings {
	margin-left: 0;
	margin-right: 0;

	& > div {
		padding-left: 8px;
		padding-right: 8px;

		@media (max-width:420px) {
			width: 100%;
		}
	}
}

.castlePanel {
	width: 100%;
	border: none;
	padding: 10px;
	background-color: white;
	border-radius: 10px;
	margin: 0 0 30px;
	text-align: center;
	transition: all 0.3s;
	position: relative;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.3);

	@media @mobile {
		text-align: center;
	}

	&:hover {
		.castleCheckBook {
			color: yellow;
			background-image: url("@{cloudUrl}go-button-hover.svg");
		}

		box-shadow: 5px 5px 6px 0 rgba(0,0,0,.075);
	}

	.SingleImagePanel {
		border: 3px solid #ffc400;
		border-radius: 5px;

		img {
			padding: 5px;
		}
	}

	.castleTitlePanel {
		margin-top: 15px;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			color: #404040;
			font-size: 20px;
			position: relative;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		color: #404040;
		.Dimbo;
		font-size: 35px;
		position: relative;
		text-align: left;

		span.oldprice {
			color: #ff0000;
			font-size: 20px;
		}

		span.newprice {
			font-size: 24px;
		}

		@media @mobile {
			text-align: center;
			align-items: center;
			justify-content: center;
		}
	}

	.castlePriceDefault {
		display: flex;
		align-items: center;
	}

	.castleCheckBook {
		background-color: transparent;
		position: relative;
		font-size: 0;
		background-image: url("@{cloudUrl}go-button.svg"),url("@{cloudUrl}go-button-hover.svg");
		width: 23%;
		height: 0;
		padding-top: 23%;
		background-size: 100%;
		background-repeat: no-repeat;
		position: absolute;
		right: 5%;
		bottom: 10px;

		@media @mobile {
			position: relative;
			display: inline-block;
			bottom: auto;
			right: auto;
		}
	}
}
/*#endregion Castle Panels*/
.DetailsTitle h1, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton {
		width: auto;
		display: inline-block;
		margin: 20px 0 10px;
		float: none;
	}
}

.footer {
	&__wrapper {
		background-color: #000;
		color: #fff;
		padding: 25px 10px;
		width: 100%;
		z-index: 1;
		position: relative;

		@media @mobile {
			text-align: center;
		}
	}

	&__flex-row {
		@media @tablet {
			display: flex;
			align-items: center;
		}
	}

	&__links {
		text-align: center;

		@media @mobile {
			text-align: center;
			margin-top: 15px;
		}

		a {
			color: white;
			margin:0 5px 5px;
			display:inline-block;

			@media @mobile{
				padding:8px 0;
			}
		}
	}

	&__BCN {
		display: block;
		max-width: 180px;

		@media @mobile {
			text-align: center;
			display: inline-block;
		}

		@media @sm {
			float: right;
		}

		img {
			margin: 0 auto;
		}
	}
}

#BouncyCastleHireLink {
	color: white;
	font-weight: 700;
}
/*#region Utiliy Classes*/
.white {
	color: #fff;
}

.text-shadow-big {
	text-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}
/*#endregion Utiliy Classes*/
/*#region Animations*/
.wow {
	visibility: hidden;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}


@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale3d(.3,.3,.3)
	}

	50% {
		opacity: 1
	}
}

.zoomIn {
	animation-name: zoomIn
}
/*#endregion Animations*/
